import { useSelector } from 'react-redux';

import { Route } from '@portal/common';
import { selectPermissions } from '@portal/store/dist/user/selectors';

import { Router } from '../routes';

import SelfServiceHome from './home/self-service/index';

const Home = () => {
  const {
    shouldRedirectIndexPageToAccount,
    shouldUseExternalEntrypointOnIndexPage,
    shouldUseSelfServiceEntrypointOnIndexPage,
    shouldRedirectIndexEntryPointToHomePage,
    shouldRedirectIndexEntryPointToMciReports,
    shouldRedirectIndexEntryPointToBenchmarkReports,
  } = useSelector(selectPermissions);

  if (shouldRedirectIndexPageToAccount) {
    Router.pushRoute('/accounts');

    return null;
  }

  if (shouldUseExternalEntrypointOnIndexPage) {
    if (shouldRedirectIndexEntryPointToHomePage) {
      Router.pushRoute('/home');

      return null;
    }

    if (shouldRedirectIndexEntryPointToMciReports) {
      Router.pushRoute(Route.MciReports);

      return null;
    }

    if (shouldRedirectIndexEntryPointToBenchmarkReports) {
      Router.pushRoute('/benchmark-reports/funnel-summary');

      return null;
    }
  }

  if (shouldUseSelfServiceEntrypointOnIndexPage) {
    return <SelfServiceHome />;
  }

  return null;
};

export default Home;
